const bodyScrollLock = require( 'body-scroll-lock/lib/bodyScrollLock.js' );
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

window.onload = function () {
  // Backwards compatibility for users that already have the version identified by id
  let btnElementsById = document.querySelectorAll("[id='schwiz-ifr-btn']");

  let btnElementsByClass = document.querySelectorAll("[class~='schwiz-ifr-btn']");
  let schwizHolder = document.getElementById('schwiz-holder');
  let iframeUrl;
  let iframeLocale;
  let ifr;
  let iframePadding = 20;

  // In case there are multiple instances
  applyEventHandlers(btnElementsByClass, btnElementsById);

  window.addEventListener('message', function(evt) {
    if (evt.data.closeWizard) {
      while (schwizHolder.hasChildNodes()) {
        schwizHolder.removeChild(schwizHolder.lastChild);
      }

      // Hide overlay
      schwizHolder.style.display = 'none';

      // Reenable body scroll
      enableBodyScroll(schwizHolder);
    }

    // Resize iframe to content
    if (evt.data.contentHeight) {
      let heightInPixels = `${getHeightCompensation(iframePadding) + parseInt(evt.data.contentHeight)}px`;
      let ifrObj = document.getElementById('scheduling-wizard-ifr-container');

      // Handle existing invisible iframe or first time added to DOM
      if (ifrObj) {
        ifrObj.style.height = heightInPixels;
      } else {
        ifr.style.height = heightInPixels;
      }
    }
  });

  function openSchedulingWizard(evt) {
    let schwizHolderStyle = {
      'position': 'fixed',
      'z-index': '999999',
      'padding': '5% 0',
      'right': 0,
      'left': 0,
      'top': 0,
      'width': '100%',
      'height': '100%',
      'text-align': 'center',
      'overflow': 'auto',
      'box-sizing': 'border-box',
      'background-color': 'rgba(0,0,0,0.4)',
      'transform': 'translate3d(0,0,0)',
      '-webkit-overflow-scrolling': 'touch'
    };
    let ifrStyle = {
      'overflow': 'hidden',
      'background-color': '#fff',
      'margin': 'auto',
      'padding': `${iframePadding}px`,
      'width': '80%',
      'max-width': '840px',
      'border': 'none',
      'box-sizing': 'border-box'
    };

    let ifrObj = document.getElementById('scheduling-wizard-ifr-container');
    let loaderElem = document.createElement('div');

    loaderElem.id = 'loading-icon';
    loaderElem.style.background = '#fff';
    loaderElem.innerHTML = 'Loading...';

    evt.preventDefault();

    ifr = document.createElement('iframe');
    ifr.id = 'scheduling-wizard-ifr-container';
    
    iframeUrl = evt.target.getAttribute('data-url');
    iframeLocale = evt.target.getAttribute('data-locale');
    iframeQueryString = '?mch=false';

    // Auto choosable resources
    if (evt.target.getAttribute('data-autoResources') && evt.target.getAttribute('data-autoResources') === 'true') {
      iframeQueryString += '&autoResources=true';
    }

    // Don't add locale for local static tests
    ifr.src = `${iframeUrl}${iframeUrl.substr(iframeUrl.length - 5) != '.html' ? '/' + iframeLocale : ''}`;
    ifr.src += iframeQueryString;
    ifr.src += `&wup=${encodeURIComponent(document.location.href)}`;
    
    ifr.scrolling = 'no';

    for (let prop in ifrStyle) {
      ifr.style[prop] = ifrStyle[prop];
    }

    for (let prop in schwizHolderStyle) {
      schwizHolder.style[prop] = schwizHolderStyle[prop];
    }

    // Expects confirmation
    //schwizHolder.appendChild(loaderElem);

    // Add the iframe to the DOM if it's absent
    if (!ifrObj) {
      schwizHolder.appendChild(ifr);
      schwizHolder.style.display = 'block';
    } else {
      ifrObj.onload = function() {
        iframeOnLoadActions(this);
      };
      ifrObj.src = ifrObj.src+'';
    }

    disableBodyScroll(schwizHolder);

    ifr.onload = function() {
      iframeOnLoadActions(this);
    };

    // Iframe onload actions for element either added to DOM or already existing
    function iframeOnLoadActions(el) {
      // In case we need a loader element
      if (loaderElem.parentNode) {
        loaderElem.parentNode.removeChild(loaderElem);
      }

      // Show overlay
      schwizHolder.style.display = 'block';
    }
  }

  /**
   * Apply event handlers to the scheduling wizard buttons.
   * Gives priority to identification by class.
   * For backwards compatibility it also accepts identification by multiple equal ids
   *
   * @param {HTMLCollection} btnElementsByClass Wizard buttons identified by class
   * @param {HTMLCollection} btnElementsById Wizard buttons idntified by id
   */
  function applyEventHandlers(btnElementsByClass, btnElementsById) {
    let btnElement;

    if (btnElementsByClass && btnElementsByClass.length > 0) {
      btnElement = btnElementsByClass;
    } else {
      btnElement = btnElementsById;
    }

    for (let i = 0, cnt = btnElement.length; i != cnt; i++) {
      btnElement[i].onclick = openSchedulingWizard;
    }
    checkAutoStart();
  }
};

/**
 * Check if we have to auto start the wizard
 */
function checkAutoStart() {
  const hashes = location.hash.split('#');
  const showBooking = hashes.indexOf('showBooking') !== -1;
  let wizrefHash = undefined;

  if (showBooking) {
    let wizardButtons = document.querySelectorAll('.schwiz-ifr-btn');

    // Check if we have a specific wizard instance reference
    for (let idx in hashes) {
      if(hashes[idx].startsWith('wizardid')) {
        wizrefHash = hashes[idx];
      }
    }

    if (wizrefHash) {
      const wizref = wizrefHash.split('_')[1];

      for (let i = 0, cnt = wizardButtons.length; i != cnt; i++) {
        const dataWizref = wizardButtons[i].getAttribute('data-wizardid');
        if (dataWizref === wizref) {
          wizardButtons[i].click();
        }
      }
    } else {
      wizardButtons[0].click();
    }
  }
}

/**
 * First attempt to fix discrepancies across browsers.
 * Leaving the browser checks it might still be needed
 */
function getHeightCompensation(iframePadding) {
  return iframePadding * 2;
  // Opera 8.0+
  var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 71
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;

  return (isSafari || isIE || isEdge) ? 40 : 20;
}